@media only screen and (max-width: 1024px) {
  .outer_page {
    flex-direction: column;
    overflow-y: scroll;
  }
  .right_content {
    overflow: unset !important;
    box-sizing: border-box;
    background-color: white;
  }
  .right_content .report_content {
    overflow: unset !important;
  }
}
.outer_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.outer_container .inner_content_container {
  flex: 2;
  position: relative;
}
.main_box {
  justify-content: space-around;
  background-color: white;
  height: 500px;
  padding-top: 20px;
  box-sizing: border-box;
}
.main_box #main {
  margin: 0 auto;
}
.outer_page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  background: white;
  padding: 30px;
  overflow: scroll;
}
.outer_page .right_content {
  flex: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.outer_page .right_content .nav_scroll {
  top: 0;
  z-index: 100;
  white-space: nowrap;
  box-sizing: border-box;
  height: 70px;
  line-height: 70px;
  overflow-x: scroll;
  background: white;
  overflow-y: hidden;
}
.outer_page .right_content .nav_scroll.fixed_title {
  position: fixed;
  top: 136px;
  left: 5%;
  width: 90%;
  border-bottom: solid 1px #f5f5f5;
}
.outer_page .right_content .nav_scroll .nav_title {
  display: inline-block;
  text-align: center;
  padding: 0 20px;
}
.outer_page .right_content .empty_nav {
  height: 70px;
}
.outer_page .right_content .inner_content {
  height: 100%;
  overflow-y: scroll;
}
.content_box {
  padding-top: 70px;
}
